import getConfig from 'next/config'

import type { Context as CartContext } from '@grandvisionhq/carts-v2'
import type {
  AddressLookupOptions,
  CheckoutSettings,
  Consent,
  Routes,
} from '@grandvisionhq/checkout-v2'
import { AddressLineFormat } from '@grandvisionhq/checkout-v2'
import type { IntlContextModel } from '@grandvisionhq/state'
import { Util as StoreUtils } from '@grandvisionhq/stores-v2'
import { isValidLuhnNumber } from '@grandvisionhq/utils'
interface SettingsInput {
  cart: CartContext.CartContextModel['cart']
  intl: IntlContextModel
}

const { publicRuntimeConfig } = getConfig()
const { orderTimeOut } = publicRuntimeConfig.checkout

export const basePath = '/checkout'
export const routes: Routes = {
  shipping: {
    label: 'Versand',
    path: `${basePath}/versand`,
  },
  overview: {
    label: 'Zusammenfassung',
    path: `${basePath}/zusammenfassung`,
  },
  payment: {
    label: 'Zahlung',
    path: `${basePath}/zahlung`,
  },
}

const getCheckoutConsents = ({
  cart,
  intl,
}: {
  cart: CartContext.CartContextModel['cart']
  intl: IntlContextModel
}): Consent[] => {
  const { getLabel, getFormattedLabel } = intl
  const hasContactLenses = cart?.contains.contactLenses
  const hasContactLensesSubscription = cart?.contains.contactLensesSubscription
  const hasPrescriptionGlasses = cart?.contains.prescriptionGlasses

  const allConsents: Consent[] = [
    {
      title:
        getFormattedLabel('checkout.consent.conditions', {
          parseLink: true,
          openLinksInNewTab: true,
          parseHTML: false,
        }) + '&nbsp;*',
      value: 'terms-conditions',
      required: true,
      errorMessage: getLabel('checkout.consent.conditions.error'),
    },
  ]

  if (hasContactLenses || hasContactLensesSubscription || hasPrescriptionGlasses) {
    allConsents.push({
      title: getLabel('checkout.consent.prescription') + '&nbsp;*',
      value: 'prescription-consent',
      required: true,
      errorMessage: getLabel('checkout.consent.prescription.error'),
    })
  }

  return allConsents
}

export const getCheckoutConfig = ({ cart, intl }: SettingsInput): CheckoutSettings => {
  const accountSettings = {
    createAccountRequired: (!!cart && cart.isAccountRequired) || cart?.contains.subscription,
    implicitAccountCreation: false,
  }

  const addressLookup: AddressLookupOptions = {
    addressLineFormat: AddressLineFormat.streetName_streetNumber,
    shouldCombineAddress: true,
  }

  const isPaybackCardNumberValid = (cardNumber: string): boolean =>
    isValidLuhnNumber('308342', cardNumber)

  return {
    account: accountSettings,
    consent: {
      implicitTermsAndConditions: false,
      items: getCheckoutConsents({ intl, cart }),
    },
    addressLookup,
    shippingDetails: {
      loadingFinished: Boolean(cart) || cart === null,
      ...(cart?.billingAddress ? { billingAddress: cart.billingAddress } : {}),
      ...(cart?.shippingAddress ? { deliveryAddress: cart.shippingAddress } : {}),
      ...(cart?.customerDetails ? { customer: cart.customerDetails } : {}),
    },
    shippingFormFields: {
      billingAddress: [
        'additionalAddressInfo',
        'additionalStreetInfo',
        'city',
        'country',
        'firstName',
        'lastName',
        'postalCode',
        'salutation',
        'streetName',
      ],
      contactDetails: ['email', 'phone', 'dateOfBirth'],
    },
    serviceStore: {
      isOptional: true,
    },
    pickupPoints: {
      showCityField: true,
    },
    shipping: {
      requiredShippingCountry: 'DE',
    },
    storeFinder: {
      showUseMyLocation: true,
      searchWhitelist: ['storeName', 'postalCode', 'streetName', 'town'] as StoreUtils.SearchKey[],
    },
    ...(orderTimeOut ? { orderCreationTimeOutLimit: orderTimeOut } : {}),
    features: {
      isCompanyEnabled: false,
      isServiceStoreEnabled: true,
    },
    login: {
      disableSignup: true,
      returnPath: cart ? `${routes.shipping?.path}?cartId=${cart.id}` : routes.shipping?.path,
    },
    routes,
    payments: {
      applePay: {
        merchantName: 'Apollo',
      },
    },
    isPaybackCardNumberValid,
    logoVariant: 'main',
  }
}
