import { ExpressPaymentMethod } from '@grandvisionhq/payments'
import type { IntlContextModel } from '@grandvisionhq/state'

export const getPageTitle = (type: ExpressPaymentMethod, intl: IntlContextModel): string => {
  const { getLabel } = intl

  return type === ExpressPaymentMethod.APPLE_PAY
    ? getLabel('checkout.express.apple.pay.pageTitle')
    : getLabel('checkout.express.pageTitle')
}
